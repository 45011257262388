import { MantineTheme, useMantineTheme, Tuple } from "@mantine/core";
import {
    lighten,
    getLuminance,
    meetsContrastGuidelines,
    tint,
    adjustHue,
} from "polished";
import { ContrastScores } from "polished/lib/types/color";
import { useMemo } from "react";

export function useMerchantTheme(primaryColor: string): MantineTheme {
    const theme = useMantineTheme();
    const memoizedValue = useMemo(
        () => createColorSet(primaryColor),
        [primaryColor]
    );

    const isLight = isLightColor(primaryColor);

    theme.colors["merchantPrimary"] = memoizedValue;
    theme.primaryColor = "merchantPrimary";
    theme.other.isLight = isLight;
    theme.other.merchantTextColor = theme.other.isLight
        ? theme.other.textColor
        : theme.colors[theme.primaryColor][6];
    theme.other.textColor = isLight ? "#333333" : "#ffffff";

    return theme;
}

export function isLightColor(merchantHex: string): boolean {
    const luminance = getLuminance(merchantHex);
    return luminance > 0.35;
}

export function createColorSet(merchantHex: string): Tuple<string, 10> {
    let backgroundColor: string = "";
    let buttonHoverColor: string = "";
    let contrastGuidelinesMet: ContrastScores = {
        AA: false,
        AAA: false,
        AALarge: false,
        AAALarge: false,
    };
    const luminance = getLuminance(merchantHex);

    function lightenColors(luminanceFloor: number = 0.6) {
        let luminanceCounter = luminanceFloor;
        while (!contrastGuidelinesMet.AA && luminanceCounter < 0.9) {
            backgroundColor = tint(luminanceCounter, merchantHex);
            contrastGuidelinesMet = meetsContrastGuidelines(
                merchantHex,
                backgroundColor
            );
            luminanceCounter += 0.02;
        }

        buttonHoverColor = lighten(0.1, merchantHex);
    }

    if (luminance > 0.35) {
        backgroundColor = tint(0.85, merchantHex);
        buttonHoverColor = adjustHue(6, merchantHex);
    } else {
        lightenColors(0.8);
    }

    return [
        backgroundColor,
        merchantHex,
        merchantHex,
        merchantHex,
        merchantHex,
        merchantHex,
        merchantHex, // keeping the primary color in the primary position
        buttonHoverColor,
        merchantHex,
        merchantHex,
    ];
}
