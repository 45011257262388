export const getMerchantPath = (merchantName: string | undefined) => {
    return `/merchants/${merchantName}`;
};

export const getMerchantLocationPath = (
    merchantName: string | undefined,
    merchantLocationName: string | undefined
) => {
    return `${getMerchantPath(merchantName)}/ml/${merchantLocationName}`;
};
