import { Address, ShippingAddress } from "types";

function isShippingAddress(
    address: Address | ShippingAddress
): address is ShippingAddress {
    return address.hasOwnProperty("zip1");
}

export function line1(
    address: Address | ShippingAddress,
    trailingComma: boolean = false
): string {
    const line1 = `${address.street1}${
        address.street2 ? ", " + address.street2 : ""
    }`;
    return trailingComma ? `${line1},` : line1;
}

export function line2(address: Address | ShippingAddress): string {
    return `${address.city}, ${address.state} ${
        isShippingAddress(address) ? address.zip1 : address.zipCode
    }`;
}

export function shippingAddressLine2(address: ShippingAddress) {
    return `${address.city}, ${address.state} ${address.zip1}`;
}

export function encodeAddress(address: Address): string {
    return encodeURI(`${line1(address)} ${line2(address)}`);
}

export function formatAddress(address: ShippingAddress | Address): string {
    return `${line1(address)} ${line2(address)}`;
}

interface AddressComponentNames {
    longName: string;
    shortName: string;
}

export function formatAddressFromGoogle(
    address: google.maps.places.PlaceResult
): ShippingAddress {
    const addressComponents = address.address_components?.reduce(
        (
            components: Record<string, AddressComponentNames>,
            { long_name, short_name, types }
        ) => {
            types.forEach(
                (t) =>
                    (components[t] = {
                        longName: long_name,
                        shortName: short_name,
                    })
            );
            return components;
        },
        {}
    );

    if (!addressComponents) {
        throw "Unable to parse address";
    }

    const street1 = () => {
        if (addressComponents["street_number"]) {
            return (
                addressComponents["street_number"].longName +
                " " +
                addressComponents["route"].shortName
            );
        } else {
            return addressComponents["route"].shortName;
        }
    };

    return {
        street1: street1(),
        city: addressComponents["locality"]
            ? addressComponents["locality"].longName
            : addressComponents["neighborhood"].longName,
        state: addressComponents["administrative_area_level_1"].shortName,
        country: addressComponents["country"].shortName,
        zip1: addressComponents["postal_code"].longName,
    };
}
