import dayjs from "dayjs";
import { FulfillmentMethod, ItemType, Merchant, Reservation } from "types";
import { OrderStatus } from "types/Orders";

export function canProxy(reservation: Reservation): boolean {
    if (reservation.isLottery && !reservation.selected) {
        return false;
    }
    return (
        [OrderStatus.UPCOMING, OrderStatus.AVAILABLE].includes(
            reservation.orderStatus
        ) &&
        reservation.allowProxy &&
        !reservation.proxy &&
        reservation.fulfillmentType == FulfillmentMethod.PICKUP
    );
}

export function canRemoveProxy(reservation: Reservation): boolean {
    return (
        [OrderStatus.UPCOMING, OrderStatus.AVAILABLE].includes(
            reservation.orderStatus
        ) &&
        !!reservation.proxy &&
        reservation.fulfillmentType == FulfillmentMethod.PICKUP
    );
}

export function isEvent(reservation: Reservation): boolean {
    return reservation.type == ItemType.EVENT;
}

export function hasTransfer(reservation: Reservation): boolean {
    return reservation.quantity != reservation.originalQuantity;
}

export function getStatusDetail(reservation: Reservation): string {
    if (
        reservation.isLottery &&
        reservation.orderStatus == OrderStatus.UPCOMING
    ) {
        return randomDrawStatus(reservation);
    }

    if (reservation.type == ItemType.EVENT) {
        return eventStatus(reservation);
    } else if (reservation.type == ItemType.MEMBERSHIP) {
        return membershipStatus(reservation);
    } else if (reservation.fulfillmentType == FulfillmentMethod.PICKUP) {
        return pickUpStatus(reservation);
    } else if (reservation.fulfillmentType == FulfillmentMethod.SHIPPING) {
        return shippingStatus(reservation);
    } else {
        return "";
    }
}

export function getOrderStatus(items: Reservation[]) {
    if (items.some((item) => item.orderStatus === OrderStatus.AVAILABLE)) {
        return OrderStatus.AVAILABLE;
    } else if (
        items.every((item) => item.orderStatus === OrderStatus.COMPLETE)
    ) {
        return OrderStatus.COMPLETE;
    } else if (items.some((item) => item.orderStatus === OrderStatus.EXPIRED)) {
        return OrderStatus.EXPIRED;
    } else {
        return OrderStatus.UPCOMING;
    }
}

export function isShippingOrder(items: Reservation[]) {
    return items.some(
        (item) => item.fulfillmentType === FulfillmentMethod.SHIPPING
    );
}

export function shouldShowBarcode(
    reservation: Reservation,
    merchant: Merchant,
    proxy: boolean = false
): boolean {
    const proxyCheck = proxy || !reservation.proxy;
    if (reservation.isLottery && !reservation.selected) {
        return false;
    }
    return (
        !hasBarCode(reservation) &&
        reservation.fulfillmentType != FulfillmentMethod.SHIPPING &&
        (shouldDisableUserClaim(merchant) ||
            reservation.type == ItemType.EVENT) &&
        proxyCheck &&
        [OrderStatus.AVAILABLE, OrderStatus.UPCOMING].includes(
            reservation.orderStatus
        )
    );
}

export function shouldShowClaimButton(
    reservation: Reservation,
    merchant: Merchant,
    proxy: boolean = false
): boolean {
    const proxyCheck = proxy || !reservation.proxy;
    return (
        reservation.fulfillmentType === FulfillmentMethod.PICKUP &&
        ![ItemType.EVENT, ItemType.MEMBERSHIP].includes(reservation.type) &&
        [OrderStatus.AVAILABLE, OrderStatus.UPCOMING].includes(
            reservation.orderStatus
        ) &&
        proxyCheck &&
        !shouldDisableUserClaim(merchant)
    );
}

export function allowClaim(
    reservation: Reservation,
    merchant: Merchant,
    proxy: boolean = false
): Boolean {
    return (
        shouldShowClaimButton(reservation, merchant, proxy) &&
        reservation.orderStatus === OrderStatus.AVAILABLE
    );
}

function shouldDisableUserClaim(merchant: Merchant): boolean {
    return merchant.businessFeatures?.disableUserClaim || false;
}

function hasBarCode(reservation: Reservation): boolean {
    return !reservation.qrCodeValue;
}

function randomDrawStatus(reservation: Reservation): string {
    if (!reservation.randomDrawHasRun) {
        return "Entered";
    } else if (reservation.randomDrawHasRun && reservation.selected) {
        return "Selected";
    } else {
        return "Not Selected";
    }
}

function eventStatus(reservation: Reservation): string {
    if (reservation.quantity < 1) {
        return "All tickets transferred";
    }
    switch (reservation.orderStatus) {
        case OrderStatus.UPCOMING:
            const dateString = dayjs(reservation.pickUpStartDate).format(
                "MMM D"
            );
            return `Event starts on ${dateString}`;
        case OrderStatus.AVAILABLE:
            return "Event is live";
        case OrderStatus.COMPLETE:
            return "Event attended";
        case OrderStatus.EXPIRED:
            return "Event ended";
    }
}

function membershipStatus(reservation: Reservation): string {
    const start = dayjs(reservation.pickUpStartDate).format("MMM D YYY");
    const end = dayjs(reservation.expireDate).format("MMM D YYY");
    switch (reservation.orderStatus) {
        case OrderStatus.UPCOMING:
            return `Starts on ${start}`;
        case OrderStatus.AVAILABLE:
            return `Active from ${start} to ${end}`;
        case OrderStatus.COMPLETE:
        case OrderStatus.EXPIRED:
            return "Membership term ended";
    }
}

function pickUpStatus(reservation: Reservation): string {
    if (reservation.proxy) {
        return "Proxy assigned";
    }

    const start = dayjs(reservation.pickUpStartDate).format("MMM D");
    const end = dayjs(reservation.expireDate).format("MMM D");
    const claimDateString = dayjs(reservation?.claimDate).format("MMM D");

    switch (reservation.orderStatus) {
        case OrderStatus.UPCOMING:
            return `Pick up starts ${start}`;
        case OrderStatus.AVAILABLE:
            return `Ready to pick up`;
        case OrderStatus.COMPLETE:
            return `Picked up on ${claimDateString}`;
        case OrderStatus.EXPIRED:
            return `Expired on ${end}`;
    }
}

function shippingStatus(reservation: Reservation): string {
    const start = dayjs(reservation.pickUpStartDate).format("MMM D YYY");
    const end = dayjs(reservation.expireDate).format("MMM D YYY");
    switch (reservation.orderStatus) {
        case OrderStatus.UPCOMING:
        case OrderStatus.AVAILABLE:
            return `Preparing order to ship`;
        case OrderStatus.COMPLETE:
        case OrderStatus.EXPIRED:
            return `Shipped`;
    }
}

export function canViewOrder(reservation: Reservation) {
    if (reservation.isLottery && !reservation.selected) {
        return false;
    }
    return true;
}
