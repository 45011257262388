import dayjs from "dayjs";
import { Frequency } from "types/Item";

export function getLongDateWithTime(date: Date): string {
    return dayjs(date).format("ddd, MMM DD [at] hh:mm a");
}

export function getShortDate(dateOne: Date): string {
    return dayjs(dateOne).format("ddd, MMM DD");
}

export function getShortTime(dateOne: Date): string {
    return dayjs(dateOne).format("hh:mm a");
}

export function getMonthDayYear(date: Date): string {
    return dayjs(date).format("MMM DD, YYYY");
}

export function getFullMonthDayYear(date: Date): string {
    return dayjs(date).format("MMMM DD, YYYY");
}

export function getRenewalDate(
    lastBillingDate: Date,
    frequency: Frequency
): string {
    //TODO: This is a temporary solution. We will get the renewal date from the server once added
    let interval = 1;
    switch (frequency) {
        case Frequency.MONTHLY:
            interval = 1;
            break;
        case Frequency.QUARTERLY:
            interval = 3;
            break;
        case Frequency.ANNUALLY:
            interval = 12;
            break;
    }
    const renewalDate = dayjs(lastBillingDate).add(interval, "month");
    return getMonthDayYear(renewalDate.toDate());
}
