import { CurrencyCode } from "types/CurrencyCode";

interface formatCentsToPriceArgs {
    cents: number;
    showZero?: boolean;
    hideTrailingZero?: boolean;
    locale?: string;
    currencyCode?: CurrencyCode;
}

export function formatCentsToPrice({
    cents,
    showZero = true,
    hideTrailingZero = false,
    locale = "en-us",
    currencyCode = CurrencyCode.USD,
}: formatCentsToPriceArgs): string {
    if (cents === 0 && !showZero) {
        return "FREE";
    }
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        ...(hideTrailingZero && {
            minimumFractionDigits: 0,
        }),
    }).format(cents / 100);
}
