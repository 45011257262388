import {
    faCloudArrowDown,
    faPersonWalking,
    IconDefinition,
    faTruck,
} from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
    FulfillmentMethod,
    Item,
    ItemTagStatus,
    VisibilityType,
    ItemType,
    AlcoholicTypes,
} from "types";
import { Variation } from "types/Item";

export function getItemTagStatus(item: Item): ItemTagStatus {
    if (item.isLottery) {
        return ItemTagStatus.RANDOM_DRAW;
    } else if (
        isOnSale(item) &&
        item.visibilityType == VisibilityType.MEMBERSHIP_ONLY
    ) {
        return ItemTagStatus.MEMBERS_ONLY;
    }

    if (isSoldOut(item)) {
        return ItemTagStatus.SOLD_OUT;
    } else if (dayjs().isBefore(item.startDate)) {
        return ItemTagStatus.COMING_SOON;
    } else if (dayjs().isAfter(item.saleEndDate)) {
        return ItemTagStatus.CLOSED;
    } else if (isNew(item)) {
        return ItemTagStatus.NEW;
    }

    return ItemTagStatus.NONE;
}

export function isSoldOut(item: Item): boolean {
    const quantityRemaining = item.variations.reduce(function (
        acc,
        current
    ): number {
        return acc + current.quantityRemaining;
    },
    0);
    return quantityRemaining < 1;
}

export function isOnSale(item: Item): boolean {
    dayjs.extend(isBetween);
    return (
        !isSoldOut(item) && dayjs().isBetween(item.startDate, item.saleEndDate)
    );
}

function isNew(item: Item): boolean {
    return (
        isOnSale(item) && dayjs().isBefore(dayjs(item.startDate).add(2, "day"))
    );
}

export function getFriendlyFulfillmentMethodName(
    itemType: ItemType,
    fulfillmentMethod: FulfillmentMethod = FulfillmentMethod.PICKUP
): string {
    // TODO remove if statement once api sends digtal type down
    if (itemType === ItemType.EVENT || itemType === ItemType.MEMBERSHIP) {
        return "Digital";
    }
    switch (fulfillmentMethod) {
        case FulfillmentMethod.PICKUP:
            return "Pick up";
        case FulfillmentMethod.SHIPPING:
            return "Shipping";
        case FulfillmentMethod.DIGITAL:
            return "Digital";
        default:
            return "";
    }
}

export function getFulfillmentMethodIcon(
    itemType: ItemType,
    fulfillmentMethod: FulfillmentMethod = FulfillmentMethod.PICKUP
): IconDefinition {
    // TODO remove if statement once api sends digtal type down
    if (itemType === ItemType.EVENT || itemType === ItemType.MEMBERSHIP) {
        return faCloudArrowDown;
    }
    switch (fulfillmentMethod) {
        case FulfillmentMethod.PICKUP:
            return faPersonWalking;
        case FulfillmentMethod.SHIPPING:
            return faTruck;
        case FulfillmentMethod.DIGITAL:
            return faCloudArrowDown;
        default:
            return faPersonWalking;
    }
}

export function isAlcoholic(item: Item): boolean {
    return AlcoholicTypes.includes(item.type);
}

export function getVolume(variation: Variation): string {
    return `${variation.volume} ${variation.volumeUnits?.toLowerCase() ?? ""}`;
}

export function isEvent(item: Item): boolean {
    return item.type === ItemType.EVENT;
}

export function itemTypeIsEvent(itemType: ItemType): boolean {
    return itemType === ItemType.EVENT;
}

export function includesPickUp(item: Item): boolean {
    return (
        item.fulfillmentTypes.includes(FulfillmentMethod.PICKUP) &&
        item.type != ItemType.EVENT
    );
}

export function isNoLongerOnSale(item: Item): boolean {
    switch (getItemTagStatus(item)) {
        case ItemTagStatus.CLOSED:
        case ItemTagStatus.SOLD_OUT:
            return true;
        default:
            return false;
    }
}

export function sortItems(itemA: Item, itemB: Item) {
    if (isMembershipOnly(itemA) && !isMembershipOnly(itemB)) {
        return -1;
    } else if (isMembershipOnly(itemB) && !isMembershipOnly(itemA)) {
        return 1;
    } else if (isOnSale(itemA) && !isOnSale(itemB)) {
        return -1;
    } else if (!isOnSale(itemA) && isOnSale(itemB)) {
        return 1;
    } else if (dayjs(itemA.startDate).isBefore(dayjs(itemB.startDate))) {
        return 1;
    } else if (dayjs(itemB.startDate).isBefore(dayjs(itemA.startDate))) {
        return -1;
    } else {
        return 0;
    }
}

export function sortItemCategories(itemA: Item, itemB: Item) {
    if (itemA.category === itemB.category) {
        return 0;
    }

    if (itemA.category === null) {
        return 1;
    }

    if (itemB.category === null) {
        return -1;
    }

    return itemA.category.displayOrder < itemB.category.displayOrder ? -1 : 1;
}

function isMembershipOnly(item: Item) {
    return item.visibilityType === VisibilityType.MEMBERSHIP_ONLY;
}

export function getVariationWithLowestPrice(variations: Variation[]) {
    return Object.values(variations).reduce(function (
        variationA: Variation,
        variationB: Variation
    ) {
        return variationA.price < variationB.price ? variationA : variationB;
    });
}

export function getDisplayVariation(item: Item): Variation {
    if (isSoldOut(item)) {
        return getVariationWithLowestPrice(item.variations);
    } else {
        const variationsWithQuantityRemaining: Variation[] =
            item.variations.filter(
                (variation) => variation.quantityRemaining > 0
            );
        return getVariationWithLowestPrice(variationsWithQuantityRemaining);
    }
}
