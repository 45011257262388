import {
    faCcVisa,
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import { PaymentMethod } from "types";

export function getBrandIcon(paymentMethod: PaymentMethod) {
    switch (paymentMethod.cardBrand) {
        case "visa":
            return faCcVisa;
        case "amex":
            return faCcAmex;
        case "diners":
            return faCcDinersClub;
        case "discover":
            return faCcDiscover;
        case "jcb":
            return faCcJcb;
        case "mastercard":
            return faCcMastercard;
        default:
            return faCreditCard;
    }
}

export function getBrandName(cardBrand: string): string {
    switch (cardBrand) {
        case "visa":
            return "Visa";
        case "amex":
            return "American Express";
        case "diners":
            return "Diner Club ";
        case "discover":
            return "Discover";
        case "jcb":
            return "JCB";
        case "mastercard":
            return "Mastercard";
        default:
            return cardBrand;
    }
}
