import { ShippingAddress } from "./Address";
import { CurrencyCode } from "./CurrencyCode";
import { FulfillmentMethod, ItemType } from "./Item";
import { Membership } from "./Membership";
import { Merchant } from "./Merchant";

export enum ReservationSource {
    TRANSFER = "TRANSFER",
    MANUAL = "MANUAL",
    DEFAULT = "DEFAULT",
}

export enum OrderStatus {
    EXPIRED = "EXPIRED",
    COMPLETE = "COMPLETE",
    AVAILABLE = "AVAILABLE",
    UPCOMING = "UPCOMING",
}

export interface Reservation {
    selected: boolean;
    unitPrice: number;
    id: string;
    uuid: string;
    dateCreated: Date;
    active: boolean;
    reservationSource: ReservationSource;
    claimed: boolean;
    fulfillmentType: FulfillmentMethod;
    reservationDate: Date;
    quantity: number;
    originalQuantity: number;
    imageUrl: string;
    title: string;
    pickUpStartDate: Date;
    expireDate: Date;
    claimDate?: Date;
    qrCodeValue: string;
    type: ItemType;
    isLottery: boolean;
    randomDrawHasRun: boolean;
    orderStatus: OrderStatus;
    productCategory: string;
    subtitle: string;
    longDescription: string;
    allowProxy: boolean;
    purchase?: Purchase;
    proxy?: Proxy;
    shippingAddress?: ShippingAddress;
    variantName?: string;
    offerVariant: {
        id: number;
    };
}

export interface Purchase {
    id: string;
    total: number;
    currencyCode: CurrencyCode;
    type: ItemType;
}

export interface Proxy {
    id: string;
    name: string;
    email: string;
}

export interface Order {
    id: string;
    business: Merchant;
    orderDate: Date;
    paymentType: string;
    total: number;
    salesTax: number;
    tip: number;
    fees: number;
    itemCount: number;
    items: Reservation[];
    last4: string;
    membership?: Membership;
}

export interface OrderHistoryResponse {
    data: Order[];
    metaData: {
        max: number;
        offset: number;
        total: number;
    };
}
